<template>
    <div class="panel">
        <div class="header">
            Associated Player Groups
        </div>
        <div class="content" style="padding:0;">
            <div class="">

                <template-playergroup class="group" v-if="groups && player_groups.length > 0"
                                      v-for="group in groups"
                                      :id="group"
                                      :groups="player_groups"
                                      :key="group"
                >
                </template-playergroup>

                <div class="loading" v-if="!groups || (groups.length > 0 && player_groups.length === 0)">
                    Loading ...
                </div>
                <div class="loading" v-if="groups && groups.length === 0">
                    None
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import TemplatePlayergroup from "@/views/app/templates/components/template-playergroup";

export default {
  name: "player-groups",
  components: {TemplatePlayergroup},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      groups: null,
      player_groups: [],
    }
  },

  computed: {
    resource() {
      return this.passed
    },
  },

  mounted() {
    this.$talker.api(`/user-groups/player-groups/${this.$route.params[this.resource.route_param]}`)
      .then((res) => {
        this.groups = res.data.data.player_groups.data.flatMap(x => x.id)
        if (this.groups.length > 0) {
          this.fetchGroups()
        }
      })
  },

  methods: {
    fetchGroups() {
      this.$talker.api('player-groups?players=true')
        .then(res => {
          this.player_groups = res.data.data;
        })
        .catch(err => {
          this.$notify.error('Failed to get player groups')
        })
    },
  }
}
</script>

<style scoped lang="scss">

.player-group, .player {
    display: flex;
    border: 1px solid $color-primary;
    border-radius: .25em;
    overflow: hidden;

    height: 100%;
    position: relative;
    min-height: 1.75em;

    .toggle {
        min-width: 1.75em;
        max-width: 1.75em;
        background: $color-primary;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;

        .icon {
            padding: 2px;
            background: #FFF;
            color: $color-primary;
            border-radius: 50%;
            width: .75em;
            height: .75em;
        }

        &.empty {
            .icon {
                color: #FFF;
                background: none;
            }
        }
    }

    .text {
        margin-left: 2.25em;
        display: flex;
        align-items: center;
    }
}

.player-group {
    margin: .5em 0;
    cursor: pointer;

    &.open {
        margin-bottom: .25em;
    }
}

.player {
    margin: .25em 1.5em;

    &:last-child {
        margin-bottom: 1em;
    }
}

.group {
    padding: 0.5em 0 0.5em 2em;
    border-bottom: 1px solid #eee;

    &:last-child {
        border: none;
    }
}

.loading {
    padding: 1em 2em;
}

</style>
<template>
    <div class="group" :class="{filtered:item.filtered}" v-if="item">
        <div class="group-info" @click.stop="item.open = !item.open">
            <div class="open">
                <awesome-icon class="icon" icon="caret-right" v-if="!item.open"/>
                <awesome-icon class="icon" icon="caret-down" v-else/>
            </div>
            <div class="name">
                <span>{{ item.attributes.name }}</span>
            </div>
            <div class="btns">
                <div class="add" @click.stop="$emit('add', item)"
                     v-if="canAdd && (!selected || (selected && !selected.includes(item.id)))">
                    <div class="btn-txt">add</div>
                    <awesome-icon class="icon" icon="plus"/>
                </div>
                <div class="add" style="pointer-events:none;font-size:0.9em;background:green"
                     v-if="selected && selected.includes(item.id)">
                    <awesome-icon class="icon" icon="check"/>
                </div>
                <div class="add" @click.stop="$emit('remove', item)" v-if="canRemove">
                    <div class="btn-txt">remove</div>
                    <awesome-icon class="icon" icon="times"/>
                </div>
                <div class="add" @click.stop="$emit('edit', item)" v-if="canEdit">
                    <awesome-icon class="icon" icon="edit"/>
                </div>
                <div class="add" @click.stop="$emit('delete', item)" v-if="canDelete">
                    <awesome-icon class="icon" icon="trash"/>
                </div>
            </div>
        </div>
        <div class="players" v-if="item.open">
            <div class="group-header">Players</div>
            <template v-if="item.players">
                <div class="player" v-if="item.players.data.length > 0"
                     v-for="player in item.players.data"
                     :class="{filtered:player.filtered}" :key="player.id">
                    {{ player.attributes.name }}
                </div>
            </template>
            <div class="none" v-else>None</div>
        </div>
        <div class="children" v-if="item.open">
            <div class="group-header" style="margin-bottom: 0.5em;">Sub groups</div>
            <template-playergroup class="child"
                                  v-if="item.children.length > 0"
                                  :can-add="canAdd"
                                  :can-edit="canEdit"
                                  :can-delete="canDelete"
                                  v-for="child in item.children"
                                  :selected="selected"
                                  :key="child.id"
                                  @add="$emit('add',$event)"
                                  @edit="$emit('edit',$event)"
                                  @delete="$emit('delete',$event)"
                                  @remove="$emit('remove',$event)"
                                  :group="child"></template-playergroup>
            <div class="none" v-else>None</div>
        </div>
    </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "template-playergroup",

  props: {
    group: {},
    groups: {},
    id: {},
    canAdd: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
    selected: {},
  },

  emits: ['add', 'delete', 'remove', 'edit'],

  data() {
    return {
      open: false,
      item: null,
    }
  },

  computed: {},

  mounted() {
    if (this.group) {
      this.item = this.group;
    } else {
      this.item = this.getGroup(this.id);
    }
  },

  methods: {
    getGroup(id) {
      let group = this.groups.find(x => x.id === id);
      return this.getGroupTree(_.cloneDeep(this.groups), _.cloneDeep(group))
    },
    getGroupTree(_groups, _parent = {id: null}) {
      _parent['open'] = false;
      _parent['filtered'] = false;
      _parent['children'] = _groups.filter(x => x.attributes.parent_id === _parent.id)
      _parent['children'].map(x => this.getGroupTree(_groups, x))
      return _parent;
    },
  }
}
</script>

<style scoped lang="scss">

.group {

    &.filtered > .group-info .name {
        background: #0ef8ff !important;
    }

    .group-info {
        display: flex;
        align-items: center;
        overflow: hidden;

        &:hover {

            cursor: pointer;

            .name {
                background: rgba(0, 0, 50, 0.05);
                text-shadow: 0 0 1px $color-primary;
            }
        }

        .open, .add {
            padding: 0.4em 0.5em;

            background: $color-primary;
            color: #fff;
            border-radius: 0.2em;
            display: flex;
            align-items: center;
            justify-content: center;

            .btn-txt {
                font-weight: 800;
                text-transform: uppercase;
                font-size: 0.6em;
                margin-right: 0.7em;
                line-height: 1em;

                @media screen and (max-width: 1000px) {
                    display: none;
                }
            }

            .icon {
                font-size: 0.8em;
            }

            &:hover {
                background: $color-primary-hover;
            }
        }

        .add {
            margin-right: 0.5em;
        }

        .open {
            width: 1em;

            &:hover + .name span {
                text-shadow: 0 0 1px $color-primary;
            }
        }

        .name {
            flex: 1;
            padding: 0.4em 0.7em;
            margin: 0 0.2em;
            border-radius: 0.3em;
            font-size: 0.9em;
            line-height: 1em;
        }
    }

    .btns {
        display: flex;
    }

    .group-header {
        font-weight: 600;
        font-size: 0.8em;
        line-height: 1em;
    }

    .none {
        font-size: 0.8em;
    }

    .players {
        margin: 0.3em 0 0.5em 2.1em;
        padding: 0.6em 0.6em;
        padding-right: 0;
        //box-shadow: 0 0 2px 0 $color-primary;
        background: rgba(0, 80, 150, 0.05);
        border-radius: 0.3em 0 0 0.3em;

        .player {
            font-size: 0.8em;
            line-height: 1.4em;

            &.filtered {
                background: #4ef98b;
            }
        }
    }

    .children {
        margin: 0 0 2em 2.1em;
        padding: 0.5em 0.6em;
        padding-right: 0;
        background: rgba(0, 80, 150, 0.05);
        border-radius: 0.3em 0 0 0.3em;

        .child {
            margin-bottom: 0.3em;
        }
    }

}


</style>
<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/admin/${resource.route}`)">Back</sc-button>
        </div>

        <div class="pd">
            <sc-form :form="form" @submit="submit" v-if="form">
                <template #field-users>
                    <div class="header">List of assigned users (check to attach, uncheck to detach)</div>

                    <div v-if="availableUsers.length > 0" class="users">
                        <sc-form-field v-for="(u,i) in availableUsers" field="" :key="`user-group-${u.id}`">

                            <label :for="`user-${u.id}-${i}`" class="user-label">
                                <input :value="u.id" :id="`user-${u.id}-${i}`" type="checkbox" v-model="users"
                                       class="user-input">
                                <div class="user-value">
                                    {{ u.attributes.first_name }} {{ u.attributes.last_name }}
                                </div>
                            </label>
                        </sc-form-field>
                    </div>
                </template>
            </sc-form>
            <br />
            <player-groups :passed="passed"></player-groups>
        </div>

    </div>
</template>

<script>
import PlayerGroups from "@/views/app/admin/user-groups/components/player-groups";
export default {
  name: "edit",
  components: {PlayerGroups},
  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
      users: [],
      availableUsers: []
    }
  },

  computed: {
    resource() {
      return this.passed
    },
  },

  mounted() {
    // Get unassignedUsers
    this.$talker.api('/users/unassigned')
      .then((res) => {
        this.availableUsers = res.data.data;
      })
      .then(() => {
        // Get assigned users
        this.$talker.api(`/users/assigned/${this.$route.params[this.resource.route_param]}`)
          .then((res) => {
            this.availableUsers = this.availableUsers.concat(res.data.data).sort((a, b) => {
              if (a.attributes.first_name > b.attributes.first_name) return 1;
              if (a.attributes.first_name < b.attributes.first_name) return -1;
              return 0;
            });
            this.users = res.data.data.map((u) => u.id);
          });
      })



    // Prepare form
    this.form = this.$sform.createForm({
      name: `Edit ${this.resource.name}`,
      url: `${this.resource.api}/${this.$route.params[this.resource.route_param]}`,
      fields: {
        name: {
          name: 'Group Name *'
        },
        domain: {
          name: 'Group Domain *'
        },
        description: {
          name: "Group Description *"
        },
        users: []
      },
      success: () => {
        this.$notify.success(`${this.resource.name} updated`)
        this.$router.push(`/app/admin/${this.resource.route}`)
      }
    })

    this.form.get();
  },

  methods: {
    submit() {
      this.form.fields.users = this.users.map((u) => {
        return {id: u}
      })
      this.form.put();
    }
  }
}
</script>

<style scoped lang="scss">

.page {
    padding-bottom: 2em;
}
.users {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5 ,1fr);
    grid-column-gap: .5em;
    grid-row-gap: .5em;
    margin: .5em 0 2em;

    ::v-deep .sc-form-row {
        flex: 1;
        margin: 0;

        .form-label-row {
            display: none;
        }

        .input {
            height: auto;
            border: 1px solid #eee;

            &:hover {
                background: #eee;
            }

            .user-label {
                display: flex;
                padding: .5em 1em;
                cursor: pointer;

                .user-input {
                    padding: 1em;
                    width: initial;
                    min-width: initial;
                    margin-right: 1em;
                    transform: scale(1.25);
                }
            }
        }
    }
}
</style>